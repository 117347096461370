<!-- *************************** 产品购买页 ****************************** -->
<template>
  <div class="andriod_dowload">
    <div class="container down_load_content">
      <img src="../../assets/ios_download.png" alt />
      <div class="download_text">
        <h1>购买Transgle 版</h1>
        <p>专为Android系统设计。一键加速优酷、爱奇艺、腾讯视频、Bilibili、QQ 音乐、 网易云音乐等中国服务，像在国内一样流畅。</p>
        <ul>
          <li>
            <img src="../../assets/mark.png" alt />
            <span>一键智能加速、多模式、多场景切换</span>
          </li>
          <li>
            <img src="../../assets/mark.png" alt />
            <span>支持加速国内99%的音视频应用及网页</span>
          </li>
          <li>
            <img src="../../assets/mark.png" alt />
            <span>精选游戏线路，低延迟、超稳定，国服游戏全支持</span>
          </li>
        </ul>
        <router-link to="/recharge">
          <button>立即购买</button>
        </router-link>
        <div class="version">
          <button>当前版本1.0.0</button>
        </div>
      </div>
    </div>
    <AcclerateWays />
  </div>
</template>
<script>
import AcclerateWays from "@/views/AcclerateWays.vue";
export default {
  components: {
    AcclerateWays
  }
};
</script>
<style lang="scss">
.andriod_dowload {
  .down_load_content {
    @include flex-between;
    margin-top: 47px;
    margin-bottom: 35px;
    & > img {
      width: 425px;
    }
    .download_text {
      width: 634px;
      text-align: left;
      h1 {
        font-size: 48px;
        font-weight: 400;
        color: #202020;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: #afadb8;
        margin-top: 18px;
        margin-bottom: 34px;
        line-height: 25px;
      }
      ul {
        li {
          vertical-align: middle;
          font-size: 18px;
          font-weight: 400;
          color: #202020;
          margin-bottom: 30px;

          img {
            width: 16px;
            vertical-align: middle;
            margin-right: 30px;
          }
        }
      }
      button {
        width: 238px;
        height: 54px;
        background: #517cfc;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
      .version {
        margin-top: 24px;
        button {
          width: 104px;
          height: 26px;
          background: rgba(81, 124, 252, 0.2);
          border-radius: 13px;
          font-size: 12px;
          font-weight: 400;
          color: #517cfc;
        }
      }
    }
  }
}
</style>